import React, { ChangeEvent, MouseEventHandler, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../../_services/localStorageService";
import Notification from "./Notification";
import { Checkbox } from "@mui/material";

type PropsType = {
  optOutKey: string;
  children: any;
};

/**
 * This component serves as a wrapper to show an information Notification from which you can opt out.
 * @param param0
 * @returns
 */
export function OptOutInfoNotification({ optOutKey, children }: PropsType) {
  const [optOut] = useState(getLocalStorageItem(optOutKey) || "false"); // We only get the state once at startup and never change it

  // const onCheckedChange: ChangeEvent<HTMLInputElement> = (event) => {
  //   setLocalStorageItem(optOutKey, (event.target as HTMLInputElement).checked.toString());
  // };
  const onCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalStorageItem(optOutKey, event.target.checked.toString());
  };

  return (
    <div>
      {optOut == "true" ? null : (
        <Notification type="info">
          <div>
            <span className="block">{children}</span>
            <div className="mt-2">
              <Checkbox color="primary" id={"opt-out-checkbox" + optOutKey} name="checkbox" onChange={onCheckedChange} />
              {/* <input
                onClick={onCheckedChange}
                id={"opt-out-checkbox" + optOutKey}
                type="checkbox"
                name="checkbox"
                className="align-middle"
              /> */}
              <label htmlFor={"opt-out-checkbox" + optOutKey} className="inline-block mx-2 my-0 align-middle text-sm font-normal italic">
                Nicht wieder anzeigen
              </label>
            </div>
          </div>
        </Notification>
      )}
    </div>
  );
}
